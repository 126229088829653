import { marked } from 'marked'
import { parse } from '@node-projects/node-html-parser-esm'

marked.setOptions({
  breaks: true
})

// helpers
const attrsWithSpaceBefore = (attrs: string) => {
  return attrs.length ? ` ${attrs}` : ''
}

const rawTextToString = (rawText: string) => {
  if (!rawText) { return '' }
  return rawText?.replace(/&nbsp;/g, ' ')?.replace(/\u00A0/g, ' ')
}
const removeSubsequentSpaces = (string: string) => {
  if (!string) { return '' }
  return string.replace(/\s\s+/g, ' ')
}

const groupSameNodeTypes = (array: Array) => {
  if (!array) { return [] }
  return array?.reduce((r, n) => {
    const lastSubArray = r[r.length - 1]
    if (!lastSubArray || lastSubArray[lastSubArray.length - 1] !== n - 1) {
      r.push([])
    }
    r[r.length - 1].push(n)
    return r
  }, [])
}

const formatAttrs = (attrs: Array) => {
  const attrsObject = Object.assign(...attrs)
  return [...Object.entries(attrsObject)].map((i) => {
    if (i[1] === null) {
      return `${i[0]}`
    } else {
      return `${i[0]}="${i[1]}"`
    }
  }).join(' ')
}

// format nodes
const formatNodes = (nodes: Array) => {
  const newNodes = []
  for (const node of nodes.childNodes) {
    const rawText = node._rawText
    if (node.nodeType === 3) {
      if (/^\s*$/.test(rawText) === false) {
        newNodes.push(node)
      }
    } else if (node.nodeType === 1 && node.rawTagName === 'br') {
      const index = nodes?.childNodes?.indexOf(node)
      const sameNodeTypes = nodes?.childNodes?.map((i) => {
        const isMatch = i.nodeType === 1 && i.rawTagName === 'br'
        return isMatch ? nodes?.childNodes?.indexOf(i) : null
      })?.filter(Boolean)
      const sameNodeTypesGrouped = groupSameNodeTypes(sameNodeTypes)
      const groupMatch = sameNodeTypesGrouped?.find(i => i?.includes(index))
      const indexWithinGroup = groupMatch?.indexOf(index)
      if (newNodes.length === 0) {
        newNodes.push(node)
      } else if (indexWithinGroup <= 0 || indexWithinGroup === groupMatch?.length - 1) {
        newNodes.push(node)
      }
    } else if (node.nodeType === 1) {
      if (node.rawTagName === 'p') {
        if (/^\s*$/.test(node.rawText) === false && node.rawText !== '&nbsp;') {
          newNodes.push(node)
        }
      } else if (node.rawTagName === 'a') {
        newNodes.push(node)
      } else {
        newNodes.push(node)
      }
    }
  }

  return newNodes?.filter(Boolean)
}

const formatNodesToString = (nodes: Array, forceTargetBlank: Boolean, firstNode: Object, lastNode: Object) => {
  const newNodes = []
  for (const node of nodes) {
    const rawText = node._rawText
    if (node.nodeType === 3) {
      const string = rawTextToString(rawText)
      const stringParsed = removeSubsequentSpaces(string)
      newNodes.push(stringParsed)
    } else if (node.nodeType === 1 && node.rawTagName === 'br') {
      newNodes.push('<br />')
    } else if (node.nodeType === 1) {
      const firstNodeAttr = firstNode === node ? { 'data-first-node': null } : {}
      const lastNodeAttr = lastNode === node ? { 'data-last-node': null } : {}

      if (node.rawTagName === 'p') {
        const attrs = formatAttrs([node.attributes, firstNodeAttr, lastNodeAttr])
        newNodes.push(`<${node.rawTagName}${attrsWithSpaceBefore(attrs)}>${formatHtmlNodesToString(node)}</${node.rawTagName}>`)
      } else if (node.rawTagName === 'a') {
        const href = node.attributes?.href
        if ((forceTargetBlank && href?.includes?.('http')) || (forceTargetBlank && href?.includes?.('https'))) {
          const attrs = formatAttrs([node.attributes, { target: '_blank' }, firstNodeAttr, lastNodeAttr])
          newNodes.push(`<${node.rawTagName}${attrsWithSpaceBefore(attrs)} class='animated-underline' data-manual data-inverted>${formatHtmlNodesToString(node, forceTargetBlank)}</${node.rawTagName}>`)
        } else if (href?.includes?.('@') && !href?.startsWith('mailto:') && !href?.includes?.('http') && !href?.includes?.('https')) {
          const attrs = formatAttrs([node.attributes, { href: `mailto:${href}` }, firstNodeAttr, lastNodeAttr])
          newNodes.push(`<${node.rawTagName}${attrsWithSpaceBefore(attrs)} class='animated-underline' data-manual data-inverted>${formatHtmlNodesToString(node, forceTargetBlank)}</${node.rawTagName}>`)
        } else {
          const attrs = formatAttrs([node.attributes, firstNodeAttr, lastNodeAttr])
          newNodes.push(`<${node.rawTagName}${attrsWithSpaceBefore(attrs)} class='animated-underline' data-manual data-inverted>${formatHtmlNodesToString(node, forceTargetBlank)}</${node.rawTagName}>`)
        }
      } else {
        const attrs = formatAttrs([node.attributes, firstNodeAttr, lastNodeAttr])
        newNodes.push(`<${node.rawTagName}${attrsWithSpaceBefore(attrs)}>${formatHtmlNodesToString(node, forceTargetBlank)}</${node.rawTagName}>`)
      }
    }
  }

  return newNodes?.filter(Boolean)?.join('')
}

const formatHtmlNodesToString = (nodes: array, forceTargetBlank = false, assignFirstLastNode = false) => {
  const nodesFormatted = formatNodes(nodes)

  const firstNode = nodesFormatted?.find(i => i.nodeType === 1)
  const lastNode = [...nodesFormatted]?.reverse()?.find(i => i.nodeType === 1)
  const firstLastNode = assignFirstLastNode ? [firstNode, lastNode] : []

  const nodesToString = formatNodesToString(nodesFormatted, forceTargetBlank, ...firstLastNode)

  return nodesToString
}

// export
export const formatStringToHtml = (string: string, forceTargetBlank = false) => {
  if (string) {
    const stringToMarkdown = marked.parse(string)
    const nodes = parse(stringToMarkdown)

    return formatHtmlNodesToString(nodes, forceTargetBlank, true)
  }
}
